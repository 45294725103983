@import url("https://fonts.googleapis.com/css2?family=IBM+Plex+Serif:wght@400;500&family=Lora:wght@400;500&family=Poppins:wght@400;600&display=swap");

/* plz subsribe to thapa technical channel 
          https://www.youtube.com/thapatechnical
         */

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}

:root {
  --text-clr: #4a5c6c;
  --bg-clr: #f2f4fc;
  --border-clr: rgb(156, 176, 193);
}

html {
  font-size: 62.5%;
}

body {
  background-color: var(--bg-clr);
}

h2 {
  font-family: "Lora", serif;
  color: var(--text-clr);
  font-size: 2rem;
  transition: all 0.5s linear;
}

a {
  text-decoration: none;
}

img {
  /* width: 25rem; */
  min-width: 10rem;
  max-height: 30rem;
  margin: 1rem 0;
  border-radius: 1rem;
  transition: all 0.3s linear;
}

/* resuable code  */
.container {
  max-width: 140rem;
  margin: auto;
  padding: 3rem;
}

.grid {
  /* width: 100%; */
  display: grid;
  gap: 3rem;
}

.grid-4-col {
  grid-template-columns: repeat(4, 1fr);
}

.card {
  width: 25rem;
  max-width: 30rem;
  min-height: 30rem;
  /* background-color: #fafaff; */
  /* padding: 2rem; */
  border-radius: 1rem;

  display: flex;
  justify-content: center;
  align-items: center;
}

/* search css  */
.search-section {
  margin: auto;
  text-align: center;
  margin: 4rem 0;
}

input {
  width: 30rem;
  border-radius: 100px;
  border: none;
  border: 1px solid var(--border-clr);
  padding: 1rem 2rem;
  font-size: 1.8rem;
  /* border-color: var(--border-clr); */
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px,
    rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
  outline: none;
  margin-top: 2rem;
}

.card-error p {
  color: red;
  margin-top: 1rem;
  font-size: 1.8rem;
}

/* Single Movie css code  */

.movie-section {
  width: 100%;
  height: 100vh;
  background-color: var(--text-clr);
  display: grid;
  place-items: center;
}

.loading {
  font-size: 3.2rem;
  color: var(--text-clr);
  text-align: center;
}

.movie-section .loading {
  font-size: 3.2rem;
  color: var(--bg-clr);
  text-align: center;
}

.movie-card {
  min-width: 30rem;
  width: 50vw;
  /* padding: 1rem; */
  background-color: var(--bg-clr);
  display: grid;
  gap: 2rem;
  grid-template-columns: 0.6fr 1fr;
  /* align-items: center; */
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  border-radius: 2rem;
}

figure {
  margin: 0;
  padding: 0;
}
.movie-card img {
  width: 100%;
  height: 100%;
  margin: 0;
  border-radius: 0;
  border-top-left-radius: 2rem;
  border-bottom-left-radius: 2rem;
  /* border-radius: 0; */
  max-height: 50rem;
  height: auto;
}

.movie-card .title {
  font-size: 2.8rem;
  color: var(--text-clr);
  font-weight: bold;
}

.card-content {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  gap: 1rem;
}

.card-text {
  font-size: 1.8rem;
}

a {
  text-align: center;
  display: flex;
  justify-content: center;
  margin-top: 2rem;
  padding: 3.2rem 1rem;
  /* background-color: var(--text-clr); */
  color: var(--text-clr);
  border: 0.2rem solid var(--text-clr);
  font-size: 1.8rem;
  border-radius: 0.5rem;
  transition: all 0.3s linear;
}

a:hover {
  background-color: var(--text-clr);
  color: var(--bg-clr);
}

a:hover h2 {
  color: var(--bg-clr);
  transform: translateY(-1.5rem);
  display: inline-block;
}

a:hover img {
  transform: scale(1.1);
}

.back-btn {
  padding: 1.2rem 3.6rem;
}

/* responsive code   */
@media (max-width: 1200px) {
  .container {
    margin: auto;
    max-width: 110rem;
  }
  .grid-4-col {
    grid-template-columns: 1fr 1fr 1fr;
  }
}

@media (max-width: 998px) {
  .container {
    max-width: 90rem;
    margin: auto;
  }
  .grid-4-col {
    grid-template-columns: 1fr 1fr;
  }

  input {
    width: 25rem;
  }

  .movie-section {
    height: auto;
    padding: 4.8rem 0;
  }

  .movie-card {
    grid-template-columns: 1fr;
  }

  .card-content {
    padding-left: 2rem;
  }

  .back-btn {
    margin-bottom: 3rem;
  }
}

@media (max-width: 768px) {
  .container {
    max-width: 70rem;
    margin: auto;
  }
  .grid-4-col {
    grid-template-columns: 1fr;
  }
}